.tree-index {
    table {
        td {
            min-width: 1in;
        }
        tr:nth-child(odd) {
            background-color: #d5d5d5;
            color: black;
        }
        th{
            background-color: transparent;
        }
    }
}
