@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Ms+Madi&family=Pinyon+Script&family=Ruthie&display=swap");

#sig1 {
    font-family: "Great Vibes", cursive;
    font-size: 24pt;
}
#sig2 {
    font-size: 24pt;
    font-family: "Ms Madi", cursive;
}
#sig3 {
    font-size: 24pt;
    font-family: "Ruthie", cursive;
}
#my-own{
    font-size: 16pt;
}
.create-signature {
    color: blue !important;
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    width: max-content;
    padding: 10px !important;
    #sig1,
    #sig2,
    #sig3 {
        font-size: 32px !important;
    }
}
.signature-block {
    background-color: white;
    margin-bottom: 2em;
    width: 100%;
    max-width: 100%;
    height: 200px;
}
.sigCanvas{
    width: 100%;
    height: 100%;
}
.document-page-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-shadow: 0 2px 8px 0 rgb(0, 0, 0);
    z-index: 5;
    position: relative;
    img{
        height: 50px;
        margin-right: 1rem;

    }
}
.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
    opacity: 1;
}
