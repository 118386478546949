.Client-Welcome {
    background-color: smoke;
    // background-color: rgb(222, 222, 228);
    // background-image: url('./biosphere.jpeg');
    // background-image: url('./forestbackground.jpeg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    min-width: 100%;
    // animation: move 80s;
    svg{
        background-color: seagreen;
        border-radius: 50%;
        margin-top: 1em;
        box-shadow: 5px 5px 30px 5px rgba(255, 255, 255, 0.651);
    }
    h1{
        color: seagreen;
        font-weight: 600;
        margin: 1em;
        font-size: 3em;
        opacity: 0;
        text-shadow: 2px 2px 2px white;
        animation: fading forwards 2s;
        animation-delay: 3s;
        text-align: center; 
        padding: .5em;
        background-color: rgba(255, 255, 255, 0.774);
        min-width: 100%;
    }
    
}

.test {
    display: flex;
    font-size: 4em;
    letter-spacing: .25em;
    color: white;
  }
  .test :nth-child(1) {
    opacity: 0;
    animation: type forwards .5s;
  animation-delay: 3s; 
  }
  .test :nth-child(2) {
    opacity: 0;
    animation: type forwards .5s;
  animation-delay: 3.1s; 
  }
  .test :nth-child(3) {
    opacity: 0;
    animation: type forwards .5s;
  animation-delay: 3.2s; 
  }
  .test :nth-child(4) {
    opacity: 0;
    animation: type forwards .5s;
  animation-delay: 3.3s; 
  }
  .test :nth-child(5) {
    opacity: 0;
    animation: type forwards .5s;
  animation-delay: 3.4s; 
  }
  .test :nth-child(6) {
    opacity: 0;
    animation: type forwards .5s;
    animation-delay: 3.5s; 
  }
  .test :nth-child(7) {
    opacity: 0;
    animation: type forwards .5s;
    animation-delay: 3.6s; 
  }
  .test :nth-child(8) {
    opacity: 0;
    animation: type forwards .5s;
    animation-delay: 3.7s; 
  }
  
  
  
svg {
    max-width: 25vw;
}
  #egyptian {
    stroke-dasharray: 10572;
    stroke-dashoffset: 1072;
    stroke: #e1b382;
    stroke-width: 4;
    animation: draw forwards 15s ;
  animation-delay: 1.5s; 
  }
  #draw1 {
    stroke-dasharray: 4572;
    stroke-dashoffset: 4572;
    animation: draw forwards 3s;
  animation-delay: 1s; 
  }
  #draw2 {
    stroke-dasharray: 4572;
    stroke-dashoffset: 4572;
    animation: draw forwards 4s ;
  animation-delay: 1.5s; 
  }
  #draw3 {
    stroke-dasharray: 4572;
    stroke-dashoffset: 4572;
    animation: draw forwards 1s ;
  animation-delay: 4s;
  }
  #draw4 {
    stroke-dasharray: 1072;
    stroke-dashoffset: 1072;
    animation: draw forwards 10s;
  }
  .Shrink {
    display: none;
    background-color: transparent;
    transition: .5s ease-in-out;
    border-radius: 50%;
  }
  .Shrink:hover{
    background-color: rgba(0, 0, 0, 0.473);
    border-radius: 50%;
    transform: scale(1.1);
  }
  
  .trunk{
    opacity: 0;
    animation: grow 2s forwards;
    transform-origin: 50% 50%;
    stroke-dasharray: 2672;
    stroke-dashoffset: 2672;
  }
  #leaf{
    opacity: 0;
    transform-origin: 50% 50%;
    animation: leafing forwards 2s;
    animation-delay: 1.15s
  }
  #leaf2 {
    opacity: 0;
    transform-origin: 50% 50%;
    animation: leafing2 forwards 2s;
    animation-delay: 1.15s
  }
  #Layer2{
  }
  #yang-border {
  opacity: 0;
    animation: grow 2s forwards;
    transform-origin: 50% 50%;
    stroke-dasharray: 3672;
    stroke-dashoffset: 3672;
    animation-delay: 2s;
  }
  #yang-right {
  opacity: 0;
    animation: yang 2s forwards;
    transform-origin: 50% 50%;
    stroke-dasharray: 3672;
    stroke-dashoffset: 3672;
    animation-delay: 3s;
  }
  #yang-left{
  opacity: 0;
    animation: yang 2s forwards;
    transform-origin: 50% 50%;
    stroke-dasharray: 3672;
    stroke-dashoffset: 3672;
    animation-delay: 2s;
  }
  .good-animation{
    opacity: 0;
    animation: good 2s forwards;
    transform-origin: 50% 50%;
    stroke-dasharray: 1672;
    stroke-dashoffset: 1672;
    animation-delay: 2s;
  }
  #Layer4{
    display: none;
  }
  @keyframes spin {
    from {
    }
    to {
    }
  }
  @keyframes yang {
    0%{
      transform: rotate(90deg);
    }
    100%{
      opacity: 1;
      transform: rotate(0deg); 
    }
    
  }
  @keyframes grow {
    0% {
  
     fill: transparent;
     stroke: white;
     stroke-width: 3px;
    }
    50%{
      fill: transparent;
      stroke: white;
      stroke-width: 3px;
      stroke-dashoffset: 1;  
    }
    100% {
      stroke-dashoffset: 1;  
      opacity: 1;
      stroke: transparent;
      stroke-width: 0px;
    }
  }
  @keyframes good {
    0% {
      opacity: 1;
     fill: transparent;
     stroke: black;
     stroke-width: 6px;
    }
    70%{
      opacity: 1;
      fill: transparent;
      stroke: white;
      stroke-width: 3px;
      stroke-dashoffset: 1;  
    }
    100% {
      stroke-dashoffset: 1;  
      opacity: 1;
      stroke: transparent;
      stroke-width: 0px;
    }
  }
  @keyframes leafing {
    from {
      
      transform:rotate(10deg) scale(.85);
      opacity: 0;
      
    }
    to{
      transform:rotate(0deg) scale(1);
      opacity: 1;
      
    }  
  }
  @keyframes leafing2 {
    from {
      
      transform:rotate(-10deg) scale(.85);
      opacity: 0;
      
    }
    to{
      transform:rotate(0deg) scale(1);
      opacity: 1;
      
    }  
  }
  @keyframes fading {
    from {

      opacity: 0;
    }
    to{

      opacity: 1;
    }
  }
  @keyframes move {
    0% {
        background-position: bottom left;
    }
    10% {
        opacity: 100;
    }
    100%{
        background-position: top right ;

    }
  }
  @keyframes draw {
    0%{
        stroke: white;
    }
    75% {
      stroke-dashoffset: 1;  
  }
    100% {
      stroke-dashoffset: 1; 
        stroke: #e1b382; 
      }
  }
  @keyframes type{ 
    from {
    transform: scale(5);
      opacity: 100;
    }
    to {
      transform: scale(1);
      opacity: 100;
    }
  } 
