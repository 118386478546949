.Admin-Dash {
    display: flex;
    flex-direction: row;
    .Admin-Dash-Body {
        background-color: rgb(222, 222, 228);
        min-width: calc(100vw - 200px);
        max-width: calc(100vw - 200px);
        min-height: 100vh;
        margin-left: 200px;
    }
}
@media (max-width: 1000px) {
    .Admin-Dash {
        display: flex;
        flex-direction: column !important;
        .Admin-Dash-Body {
            min-width: calc(100vw - 0px) !important;
            max-width: calc(100vw - 0px) !important;
            min-height: 100vh !important;
            margin-left: 0px !important;
            padding-top: 60px;
        }
    }
}
.AdminMobileNav {
    position: fixed;
    top:0;
    min-width: 100vw;
    background-color: white;
    z-index: 500;
}

@media (min-width: 1001px){
    .AdminMobileNav{
        display: none;
    }
}