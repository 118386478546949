.onboarding-demo {
    display: flex;
    flex-direction: column;
}
.signature-block {
    background-color: white;
    margin-bottom: 2em;
}
.paper-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 5% 0 5%;
    background-color: rgb(211, 211, 211);
    min-height: 100vh;
    .paper {
        min-height: 50vh;
        max-width: 8.5in;
        background-color: white;
        color: black;
        display: flex;
        flex-direction: column;
        padding: 5%;
        box-shadow: 7px 9px 3px -1px rgba(0, 0, 0, 0.64);
        .card {
            max-width: 8.5in;
        }
        h1 {
            text-align: center;
        }
    }
    .input-box,
    .date-box {
        white-space: nowrap;
        padding: 5pt;
        margin: 5pt 0;
        border: solid 1px blue;
        color: blue;
        border-radius: 3px;
        background-color: rgb(210, 210, 255);
        line-height: 2.5;
    }
}
