.CircularProgressbar-path {
  stroke: seagreen !important;
}
.CircularProgressbar-trail {
  stroke: gainsboro !important;
}
.CircularProgressbar-text {
  fill: seagreen !important;
}
.CircularProgressbar-background {
}
.chart-row {
  display: flex;
  margin-bottom: 1em;
  flex-wrap: wrap;
  .chart-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    border-radius: 5px;
    strong {
      font-size: 14px;
    }
  }
}
@media(max-width: 1000px){
  #adjust-width{
    min-width: 100%;
  }
}
