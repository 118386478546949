@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
.App{
    font-family: 'Open Sans', sans-serif;
max-width: 100vw;
overflow: hidden;
}
#icon{
    margin-right: 1em;
}
#supertokens-root{

    position: relative;
    padding-top: 30px;
    min-height: 100vh;
    background-image: linear-gradient(to top, rgba(0, 128, 0, 0.507), white);
    background-image: url('./homebackground.jpg');
    background-position: top center;
    background-size: cover;
}