.pdf {
    width: 8.5in;
    max-width: 8.5in;
}
@media print {
    @page {
        margin: 1in;
        @bottom-center {
          content: "Page " counter(page) " of " counter(pages);
        }
      }
}
@page {
    margin: 1in;
    @bottom-center {
      content: "Page " counter(page) " of " counter(pages);
    }
  }
