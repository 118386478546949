.Admin-Nav::-webkit-scrollbar {
  display: none;
}
.Admin-Nav {
    min-width: 200px;
    max-width: 200px;
    min-height: 100vh;
    background-color: white;
    position: fixed;
    top: 0;
    left:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100vh;
    overflow-y: scroll;
    // scrollbar-color: #6969dd #e0e0e0;
    // scrollbar-width: thin;
    h4{
        color: darkslategray;
    }
    img{
      // border: solid 5px seagreen;
      // border-radius: 50%;
    }
    
}
@media (max-width: 1000px){
  .Admin-Nav {
    display: none;
  }
}