.marketplace-dash-content{
    padding: 1em;
    margin: 1em;
    margin-right: 2em;
    background-color: white;
    border-radius: 5px;
    .market-nav-bar{

    }   
    .vendor-list{
        display: flex;
        flex-wrap:wrap;
        .card{
            margin-right: 1em;
            margin-bottom: 1em;
        }
    }
}
#market-icon{
    background-color: seagreen;
    border-radius: 50%;
    padding: 4px;
}
@media (max-width: 750px) {
    .vendor-list{
        justify-content: center;
        .card{
            margin-right: 0 !important;
        };
    }
}
@media (max-width: 1000px) {
    .marketplace-dash-content {
        margin-right: 1em !important;
    }
}

