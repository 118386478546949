.Client-Nav {
    min-width: 200px;
    max-width: 200px;
    min-height: 100vh;
    background-color: white;
    position: fixed;
    top: 0;
    left:0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.13);
    h4{
        color: darkslategray;
    }
 
    .Client-Nav-Top{
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 100%;
      #logo{
        // border: solid 12px seagreen;
        // background-color: seagreen;
        // border-radius: 50%;

      }

    }
}

@media (max-width: 1000px){
  .Client-Nav {
    display: none;
  }
}