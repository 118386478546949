.admin-dash-content{
    padding: 1em;
    margin: 1em;
    margin-right: 2em;
    background-color: white;
    border-radius: 5px;
    tr{
        cursor: pointer;
    }
    tr:hover{
        // background-color: rgba(46, 139, 86, 0.575);
        td{
            // color: white !important;
        }
    }
}

.top-nav{

    // background-color: white;
    // margin: 1em;
    // margin-right: 2em;
    // width: auto;
    // border-radius: 10px;
    // box-shadow: 1px 3px 20px 6px rgba(0,0,0,0.38);
}
@media(max-width: 1000px){
    .top-nav{
        display: none;
    }
    .admin-dash-content{
        margin-right: 1em !important;
    }
}
