.calc-card {
    margin: 10px;
    width: 18rem;
    height: 18rem;
}
.calc-container {
    display: flex;
}
.calc-card-2 {
    margin: 10px;
    min-width: 18rem;
}
.chart-card {
    margin: 10px;
    min-width: calc(36rem + 20px);
    min-height: 18rem;
}
@media (max-width: 1000px) {
    .calc-container {
        flex-wrap: wrap;
    }
    .calc-card {
        min-width: calc(100% - 20px) !important;
        max-width: calc(100% - 20px) !important;
    }
    .chart-card {
        min-width: calc(100% - 20px) !important;
        max-width: calc(100% - 20px) !important;
    }
}
.calculator-page {
    background-color: gainsboro;
    min-width: 100%;
    min-height: 100vh;
    max-width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px;

    .input-container {
        margin: 10px;
        background-color: white;
        padding: 10px;
        border-radius: 0.25rem;
        max-width: 500px;
    }
    .calculator-table {
        td {
            max-width: 45vw;
            padding-bottom: 10px;
            padding-right: 20px;
            padding-top: 10px;
            // border-bottom: 1px rgba(0, 0, 0, 0.418) solid;
        }
        tr td:nth-child(2) {
            text-align: right;
        }
    }
}
