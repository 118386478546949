.new-client-window{
    min-width: 100vw;
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
   background-color: gainsboro;
   display: flex;
   flex-direction: column;
   align-items: center;
   .Form-Box{
       margin-top: 1em;
       display: flex;
       flex-direction: column;
       align-items: center;
       background-color: white;
       width: 80vw;
       max-width: 600px;
       padding: 1em;
       border-radius: 5px;
       img{
           margin-bottom: 1em;
       }
   }

}