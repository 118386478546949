.filter-bar{
    background-color: rgb(218, 218, 218);
    padding: 1em;
    border-radius: 12px;
}
.modal-half-column{
    min-width: 50%;
    padding: 1em;
    .list-scroll{
        max-height: 55vh;
        overflow-y: scroll;
        border: solid 12px rgb(218, 218, 218);;
        min-height: 55vh;
        border-radius: 12px;
        input{
            cursor: pointer;
            height: 20px;
            width: 20px;
        }
    }
    .full-scroll{
        max-height: 83vh;
        overflow-y: scroll;
    }
    .item-25{
        min-width: 25%;
    }
}