$block-size: 150px;
.admin-dash-row{
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.Dash-Block {
    min-width: $block-size;
    max-width: $block-size;
    min-height: $block-size;
    max-height: $block-size;
    border-radius: 15px;
    background-color: #d1e7dd;
    background-color:seagreen;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: ease-in-out .15s;
    cursor: pointer;
    color: white;
    margin: 12px;
    strong{
        margin-top: 12px;
        font-size: 18px;
    }
}
.Dash-Block:hover{
    transform: scale(1.025);
    box-shadow: 5px 5px 15px -2px rgba(0,0,0,0.47);
}
